import Events from './events';
import "../../node_modules/slick-carousel/slick/slick";

jQuery( document ).ready(function($) {

    Events();
    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href');

        e.preventDefault();

        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    $('.newsletter').on('submit', function(e) {
       e.preventDefault();
       const DATA = $(this).serialize();
   
       $.ajax({
           type: 'POST',
           url: $(this).attr('action'),
           dataType: 'json',
           data: DATA,
           xhrFields: {
               withCredentials: false,
           },
           success: function (data) {
               $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
           },
           error: function (err) {
               console.log(err);
           }
       });
    });

   $('.scroll').on('click', function(e) {
       const TARGET = $(this).attr('href');
   
       e.preventDefault();
   
       $('body, html').animate({
           scrollTop: $(TARGET).offset().top
       });
   });

    $('.video-carousel').slick({
        slidesToShow: 1,
        prevArrow:"<button class='prev-arrow'><i class='fal fa-chevron-left'></i><span class='sr'>Previous</span></button>",
        nextArrow:"<button class='next-arrow'><i class='fal fa-chevron-right'></i><span class='sr'>Next</span></button>"
    });
    $('.music-carousel').slick({
        slidesToShow: 3,
        prevArrow:"<button class='prev-arrow'><i class='fal fa-chevron-left'></i><span class='sr'>Previous</span></button>",
        nextArrow:"<button class='next-arrow'><i class='fal fa-chevron-right'></i><span class='sr'>Next</span></button>",
        responsive: [
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
    });
});